import React, { PureComponent } from 'react';
import { Container } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import Head from 'next/head';
import flow from 'lodash.flow';
import { connect } from 'react-redux';
import { FaFacebook } from 'react-icons/fa';
import { pascalize } from 'humps';

import { i18n, withTranslation } from '../../i18n';
import AuthService from '../../services/Auth';
import BannerLayout from '../../layouts/Banner';
import Button from '../../components/UI/Button';
import PageTitle from '../../components/UI/PageTitle';
import Separator from '../../components/UI/Separator';
import Error from '../../components/Forms/Error';
import Input from '../../components/Forms/Input';
import Link from '../../components/Navigation/Link';
import withoutAuth from '../../services/Auth/withoutAuth';
import { setToken } from '../../services/Auth/actions';
import { routerPush } from '../../utils/router';
import * as formValidators from '../../utils/formValidation';
import ConnectFacebookToAccount from './ConnectFacebookToAccount';
import styles from './styles.css';

class Login extends PureComponent {
  state = {
    isSubmitting: false,
    isLoggingInWithFacebook: false,
    facebookLoginError: null,
  };

  static async getInitialProps() {
    return {
      namespacesRequired: ['common'],
    };
  }

  componentDidMount() {
    AuthService.removeTokenFromStorage();
  }

  handleFormSubmit = async formValues => {
    const { t, setToken } = this.props;

    let response;

    this.setState({ isSubmitting: true });

    try {
      response = await AuthService.login(undefined, formValues.email, formValues.password);
    } catch (error) {
      this.setState({ isSubmitting: false });
      if (error.body && error.body.errors) {
        if (error.body.errors.password === 'no_match') {
          return { password: t('error.no_match') };
        }
        return error.body.errors;
      }
      return;
    }

    this.handleLoginSuccess(response);
  };

  handleLoginWithFacebook = () => {
    window.openFB.login(this.handleLoginWithFacebookCallback, { scope: 'email' });
    this.setState({ isLoggingInWithFacebook: true, facebookLoginError: null });
  };

  handleLoginWithFacebookCallback = async fbLoginResponse => {
    if (!fbLoginResponse.status || fbLoginResponse.status !== 'connected') {
      this.setState({ isLoggingInWithFacebook: false });
      return;
    }

    let response;
    try {
      response = await AuthService.facebookLogin(undefined, fbLoginResponse.authResponse.accessToken);
    } catch (error) {
      if (error && error.body && error.body.error === 'NO_MATCHING_USER' && error.body.facebookCredentials) {
        this.setState({
          facebookCredentialToConnectToAccount: error.body.facebookCredentials,
          isLoggingInWithFacebook: false,
        });
      } else {
        this.setState({
          isLoggingInWithFacebook: false,
          facebookLoginError: error && error.body && error.body.error,
        });
      }

      return;
    }

    this.handleLoginSuccess(response);
  };

  handleLoginSuccess = response => {
    AuthService.storeToken(response.token);
    setToken(response.token);
    routerPush('/profile');
  };

  handleCloseFacebookConnect = () => {
    this.setState({ facebookCredentialToConnectToAccount: null });
  };

  validateForm = formValues => {
    const errors = {};

    formValidators.required(formValues, errors, 'email');
    formValidators.required(formValues, errors, 'password');

    return errors;
  };

  render() {
    const { t } = this.props;
    const {
      isLoggingInWithFacebook,
      facebookLoginError,
      isSubmitting,
      facebookCredentialToConnectToAccount,
    } = this.state;

    return (
      <BannerLayout small>
        <Head>
          <title>
            {t('siteTitle')} | {t('login')}
          </title>
        </Head>
        <PageTitle as="h2" alignment="center" className="mb-4">
          {t('login')}
        </PageTitle>
        <Form
          onSubmit={this.handleFormSubmit}
          validate={this.validateForm}
          render={({ handleSubmit, pristine, invalid, values, errors }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  component={Input}
                  className="mb-3"
                  type="email"
                  name="email"
                  label={t('emailAddress')}
                  required
                />
                <Field component={Input} className="" type="password" name="password" label={t('password')} required />
                <div className={styles.forgotPassword}>
                  <Link href="/forgot-password">
                    <a>{t('forgotPassword')}?</a>
                  </Link>
                </div>
                <Button className="mt-3" level="primary" type="submit" processing={isSubmitting} fullWidth>
                  {t('login')}
                </Button>
              </form>
            );
          }}
        />

        <Separator className="mt-4 mb-4" label={t('or')} />

        <Button
          level="facebook"
          type="button"
          onClick={this.handleLoginWithFacebook}
          fullWidth
          processing={isLoggingInWithFacebook}
          icon={<FaFacebook />}
        >
          {t('logInWithFacebook')}
        </Button>

        {facebookLoginError && <Error className="mt-3">{facebookLoginError}</Error>}

        {facebookCredentialToConnectToAccount && (
          <ConnectFacebookToAccount
            {...facebookCredentialToConnectToAccount}
            handleLoginSuccess={this.handleLoginSuccess}
            handleClose={this.handleCloseFacebookConnect}
          />
        )}
      </BannerLayout>
    );
  }
}

const mapDispatchToProps = {
  setToken,
};

export default flow(
  connect(
    null,
    mapDispatchToProps,
  ),
  withTranslation('common'),
  withoutAuth,
)(Login);
