import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from './styles.css';

class Separator extends PureComponent {
  render() {
    const { label, className, ...rest } = this.props;

    return (
      <span className={cx(styles.separator, className)} {...rest}>
        <span className={styles.inner}>{label}</span>
      </span>
    );
  }
}

Separator.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Separator;
