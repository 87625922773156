import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Form } from 'react-bootstrap';
import cx from 'classnames';

import styles from './Error.css';

class Input extends PureComponent {
  render() {
    const { children, className, ...rest } = this.props;

    return (
      <div className={cx(styles.error, className)} {...rest}>
        {children}
      </div>
    );
  }
}

export default Input;
