import { PureComponent } from 'react';
import cx from 'classnames';
import { Container } from 'react-bootstrap';

import Base from './Base';
import PageTitle from '../components/UI/PageTitle';
import styles from './styles.css';

class Banner extends PureComponent {
  render() {
    const { children, small } = this.props;

    return (
      <Base>
        <Container
          className={cx(styles.bannerLayoutContainer, {
            [styles.smallContainer]: small,
          })}
        >
          {children}
        </Container>
      </Base>
    );
  }
}

export default Banner;
