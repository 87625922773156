import React, { Component } from 'react';

import { routerReplace } from '../../utils/router';

import AuthService from '.';

const getDisplayName = Component => Component.displayName || Component.name || 'ComponentWithoutAuth';

export const withoutAuth = WrappedComponent =>
  class extends Component {
    static displayName = `withoutAuth(${getDisplayName(WrappedComponent)})`;

    static async getInitialProps(ctx) {
      let token;
      if (typeof ctx.query.logout === 'undefined') {
        token = AuthService.getTokenFromStorage(ctx);

        if (token) {
          if (ctx.req) {
            ctx.res.writeHead(302, { Location: '/profile' });
            ctx.res.end();
            return;
          }
          routerReplace('/profile');
        }
      }

      const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(ctx));

      return { ...componentProps, token };
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

export default withoutAuth;
