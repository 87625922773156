import { i18n } from '../i18n';

export const required = (formValues, errors, field) => {
  if (
    typeof formValues[field] === 'undefined' ||
    formValues[field] === null ||
    (typeof formValues[field] === 'string' && formValues[field].trim().length === 0)
  ) {
    errors[field] = 'required';
  }
};

export const translateError = error => {
  if (typeof error === 'object') {
    const keys = Object.keys(error);
    keys.map(key => {
      error[key] = translateError(error[key]);
    });
  } else if (typeof error === 'string') {
    error = i18n.t(`error.${error}`);
  }

  return error;
};
