import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { FaFacebook } from 'react-icons/fa';

import Input from '../../components/Forms/Input';
import Error from '../../components/Forms/Error';
import Button from '../../components/UI/Button';
import Link from '../../components/Navigation/Link';
import * as formValidators from '../../utils/formValidation';
import { withTranslation } from '../../i18n';
import API from '../../services/API';
import styles from './styles.css';

class ConnectFacebookToAccount extends PureComponent {
  state = {
    isSubmitting: false,
  };

  handleFormSubmit = async formValues => {
    const { accessToken, handleLoginSuccess } = this.props;

    let response;

    this.setState({ isSubmitting: true });

    try {
      response = await API.fetch(undefined, '/auth/connect-facebook-account', {
        ...formValues,
        access_token: accessToken,
      });
    } catch (error) {
      this.setState({ isSubmitting: false });
      if (error && error.body) {
        if (error.body.errors) {
          return formValidators.translateError(error.body.errors);
        } else if (error.body.error) {
          return { [FORM_ERROR]: formValidators.translateError(error.body.error) };
        }
      }
      return;
    }

    handleLoginSuccess(response);
  };

  validateForm = formValues => {
    const errors = {};

    formValidators.required(formValues, errors, 'email');
    formValidators.required(formValues, errors, 'password');

    return errors;
  };

  render() {
    const { t, handleClose } = this.props;
    const { isSubmitting } = this.state;

    return (
      <Modal show={true} onHide={() => {}} centered>
        <Modal.Body>
          <p>{t('connectAccountMessage')}</p>
          <Form
            onSubmit={this.handleFormSubmit}
            validate={this.validateForm}
            render={({ handleSubmit, pristine, invalid, values, submitError }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <Field
                    component={Input}
                    className="mb-3"
                    type="email"
                    name="email"
                    label={t('emailAddress')}
                    required
                  />
                  <Field component={Input} type="password" name="password" label={t('password')} required />
                  <div className={styles.forgotPassword}>
                    <Link href="/forgot-password">
                      <a>{t('forgotPassword')}?</a>
                    </Link>
                  </div>
                  <Button
                    className="mt-2"
                    level="facebook"
                    type="submit"
                    processing={isSubmitting}
                    icon={<FaFacebook />}
                    fullWidth
                  >
                    {t('connectAccount')}
                  </Button>
                  {submitError && <Error className="mt-4">{submitError}</Error>}

                  <Button
                    className="mt-3"
                    level="secondary"
                    type="button"
                    disabled={isSubmitting}
                    onClick={handleClose}
                    fullWidth
                  >
                    {t('cancel')}
                  </Button>
                </form>
              );
            }}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

ConnectFacebookToAccount.propTypes = {
  id: PropTypes.any.isRequired,
  accessToken: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default withTranslation('common')(ConnectFacebookToAccount);
